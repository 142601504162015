<template>
	<div class="view pa24" >
		<div style="display: flex;flex-wrap: wrap;"> 
		<!-- <div class="mr10">
			<span class="mr10">订单状态</span>
			<el-select clearable v-model="search.status" class="w220 mb10 mr10" placeholder="请选择状态">
				<el-option v-for="(item,index) in fashiList" :key="'info4'+ index" :label="item.name"
					:value="item.index">
				</el-option>
			</el-select>
		</div> -->
			<div class="mr10">
				<span class="mr10">平台订单号</span>
				<el-input clearable class="w220 mr10" v-model="search.name" placeholder="请输入平台订单号" />
			</div>
			<div class="mr10"> 
				<span class="mr10">商户订单号</span>
				<el-input clearable class="w220 mr10" v-model="search.bank" placeholder="请输入商户订单号" />
			</div>
			<div class="mr10">
				<span class="mr10">订单金额</span>
				<el-input clearable class="w220 mr10" v-model="search.money" placeholder="请输入金额" />
			</div>
			<div class="mr10">
				<span class="mr10">申请时间</span>
				<el-date-picker v-model="search.date" type="daterange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
			</div>
			<div class="mr10">
				<span class="mr10">处理时间</span>
				<el-date-picker v-model="search.date1" type="daterange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
			</div>
			<!-- <el-button class="ml10" type="primary" @click="search">查询</el-button> -->
			<!-- <el-button class="mr10" type="danger" @click="">导出</el-button> -->
		</div>
		<div class="mt20">
			 <el-tag effect="plain" class="mr10">成功次数：0</el-tag>
			 <el-tag effect="plain" class="mr10" type="success">成功金额：0</el-tag>
			 <el-tag effect="plain" type="info">成功费用：0</el-tag>
		</div>
		<commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
			@handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
			<template v-slot:table>
				<el-table-column prop="windowsName" align="center" label="平台订单号"  />
				<el-table-column prop="windowsPerson" align="center" label="商户订单号" />
				<el-table-column prop="createTime" align="center" label="商户" />
				<el-table-column prop="createName" align="center" label="产品"  />
				<el-table-column prop="createName" align="center" label="通道"  />
				<el-table-column prop="createName" align="center" label="订单金额"  />
				<el-table-column prop="createName" align="center" label="费用"  />
				<el-table-column prop="createName" align="center" label="到账金额"  />
				<el-table-column prop="createName" align="center" label="申请时间"  />
				<el-table-column prop="createName" align="center" label="处理时间"  />
				<el-table-column prop="createName" align="center" label="订单状态"  />
				<el-table-column prop="createName" align="center" label="操作"  />
			</template>
		</commonTable>
		<el-dialog title="新增银行卡" :visible.sync="centerDialogVisible" :modal-append-to-body="false" width="50%" center
			@close="close">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
				<el-form-item label="用户账号" prop="account">
					<el-input clearable v-model="ruleForm.account" placeholder="请输入账号"></el-input>
				</el-form-item>
				<el-form-item label="银行名称" prop="bank">
					<el-input clearable v-model="ruleForm.bank" placeholder="请输入银行名称"></el-input>
				</el-form-item>
				<el-form-item label="支行名称" prop="zhihang">
					<el-input clearable v-model="ruleForm.zhihang" placeholder="请输入支行名称"></el-input>
				</el-form-item>
				<el-form-item label="开户名" prop="name">
					<el-input clearable v-model="ruleForm.name" placeholder="请输入开户名"></el-input>
				</el-form-item>
				<el-form-item label="银行卡号" prop="ka">
					<el-input clearable v-model="ruleForm.ka" placeholder="请输入银行卡号"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="desc">
					<el-input clearable v-model="ruleForm.desc" type="textarea" :rows="4"
						placeholder="请输入备注"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="determine" v-if="bdetermine">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import commonTable from "@/components/common/commonTable";
	import {
		mapState
	} from 'vuex';
	import {} from "@/api/settlement";
	export default {
		name: '',
		data() {
			return {
				search:{
					name:'',
					bank:'',
					money:'',
					status:'',
					date:''
				},
				fashiList: [],
				tableData: [],
				currentPage: 1, //当前页
				pageSize: 10, //显示条数
				loading: false, //表格加载
				total: 0, //总条数
				centerDialogVisible: false,
				bEdit: false,
				bdetermine: true,
				editUpdate: true,
				ruleForm: {
					account: "",
					bank: "",
					zhihang: "",
					name: "",
					ka: "",
					desc: "",
				},
				rules: {
					account: [{
						required: true,
						message: "请输入账号",
						trigger: "blur"
					}],
					bank: [{
						required: true,
						message: "请输入银行名称",
						trigger: "blur"
					}],
					zhihang: [{
						required: true,
						message: "请输入支行名称",
						trigger: "blur"
					}],
					name: [{
						required: true,
						message: "请输入开户名",
						trigger: "blur"
					}],
					ka: [{
						required: true,
						message: "请输入银行卡号",
						trigger: "blur"
					}],
				},
			};
		},
		components: {
			commonTable,
		},
		mounted() {
			// this.queryPage();
		},

		methods: {
			async queryPage() {
				let data = {
					pageSize: this.pageSize,
					pageNum: this.currentPage,
				};
				try {
					this.loading = true;
					const result = await selectWindowsConfigPageList(data);
					this.loading = false;
					const {
						total,
						list
					} = result.data.pageInfo;
					for (let i in list) {
						list[i].createTime = this.renderTime(list[i].createTime)
						if (list[i].status == 0) {
							list[i].status = true
						} else {
							list[i].status = false
						}
					}
					this.tableData = list;
					this.total = total;
				} catch (error) {
					this.tableData = []
					this.loading = false;
				}
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.queryPage();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.queryPage();
			},
			/**@method 编辑
			 * @param {Object} val - 当前点击行的值
			 */
			edit(val, b) {
				let data = JSON.parse(JSON.stringify(val));
				this.centerDialogVisible = true;
				this.ruleForm.account = data.windowsName;
				this.ruleForm.bank = data.windowsPerson;
				this.ruleForm.zhihang = data.status;
				this.ruleForm.name = data.officeId
				this.ruleForm.ka = data.officeId
				this.ruleForm.desc = data.officeId
				this.bdetermine = b;
				this.bEdit = b;
				this.windowsConfigId = data.windowsConfigId
			},
			/**@method 添加 */
			determine() {
				this.$refs.ruleForm.validate(async (valid) => {
					if (valid) {
						try {
							let data = {
								account: this.ruleForm.account,
								bank: this.ruleForm.bank,
								zhihang: this.ruleForm.zhihang,
								name: this.ruleForm.name,
								ka: this.ruleForm.ka,
								desc: this.ruleForm.desc,
							};
							this.$message({
								message: "正在保存",
							});
							if (this.editUpdate) {
								data.windowsConfigId = this.windowsConfigId
								await upWindowsConfig(data);
							} else {
								await addWindowsConfig(data);
							}
							this.centerDialogVisible = false;
							this.$message.closeAll();
							this.$message({
								message: "保存成功",
								type: "success",
							});
							await this.queryPage();
						} catch (error) {
							this.$message.closeAll();
							this.$message({
								message: error.message,
								type: "error",
							});
							this.centerDialogVisible = false;
						}
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			/**@method 删除
			 * @param {String}
			 */
			async del(val) {
				try {
					await delWindowsConfig({
						windowsConfigId: val
					});
					await this.queryPage();
				} catch (error) {
					console.log(error);
				}
			},
			/**@method 模态框关闭的回调 */
			close() {
				(this.ruleForm = {
					account: "",
					bank: "",
					zhihang: "",
					name: "",
					desc: "",
					ka: "",
				}),
				this.centerDialogVisible = false;
			},
		},
	};
</script>

<style lang="scss" scoped>
</style>